import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConstantsService } from './constants.service';
import { map } from 'rxjs/operators';
import { apis } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from '../models/user';
import { IAlertService } from '../libs/ialert/ialerts.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = `${apis.baseUrl}`;
  dataStatusSubscribe = 'fetching';
  userLoggedInSource = new BehaviorSubject(false);
  scrollBottom: boolean;
  scrollBottomChange = new Subject<boolean>();
  userImage = new Subject<string>();
  notification = new Subject<string>();
  userLoggedInObs = this.userLoggedInSource.asObservable();
  user: User;
  categoriesList: any = [];
  subsPackageList: any = [];
  menuState: any = 'dashboard'
  constructor(
    public http: HttpClient,
    public cs: ConstantsService,
    private ts: TranslateService,
    public alert: IAlertService
  ) {
    this.baseUrl = apis.baseUrl;
    this.scrollBottom = false;
    this.scrollBottomChange.subscribe((value) => {
      this.scrollBottom = value;
    });
    if (localStorage.getItem('token')) {
      this.user = JSON.parse(localStorage.getItem('user') as '');
      this.userLoggedInSource.next(true);
    } else {
      this.userLoggedInSource.next(false);
    }
  }

  toggleScrollBottom(value: boolean): void {
    this.scrollBottomChange.next(value);
  }

  login(params: any): Observable<any> {
    const url = `${this.baseUrl}/public/login`;
    const addCartUrl = `${this.baseUrl}/buyer/cart/add`;
    const updateCartUrl = `${this.baseUrl}/buyer/cart/update`;

    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);

          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  otpLogin(params: any): Observable<any> {
    const url = `${this.baseUrl}/public/login-with-otp`

    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  preLogin(params: any) {
    const url = `${this.baseUrl}/public/pre-login`;
    return this.http.post(url, params);
  }

  googleLogin(): Observable<any> {
    const url = `${this.baseUrl}/login/${'google'}`;

    return this.http.get<any>(url).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          // console.log(this.user)
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }

  register(params: any): Observable<any> {
    const url = `${this.baseUrl}/register`;

    return this.http.post<any>(url, params).pipe(
      map((resp) => {
        if (resp && resp.success && resp.data.token) {
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('user', JSON.stringify(resp.data));
          this.user = resp.data;
          this.userLoggedInSource.next(true);
        }

        return resp;
      })
    );
  }


  doUserRedirects(resp: any, router: Router, url?: any) {
    switch (resp.data.user_type) {
      case ConstantsService.USER_ROLES.ADMIN || 'Admin': {
        router.navigate(['/admin/dashboard']);
        break;
      }
      case ConstantsService.USER_ROLES.USER || 'User': {
        router.navigate(['/user/dashboard']);
        break;
      }
      case ConstantsService.USER_ROLES.MANAGER || 'Manager': {
        router.navigate(['/manager/brand-profile']);
        break;
      }
    }
  }

  logOut(): boolean {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.user.id = 0;
    this.userLoggedInSource.next(false);

    return true;
  }

  logOutSession(): Observable<any> {
    const url = `${this.baseUrl}/logout`;
    return this.http.post<any>(url, {});
  }

  isAuthenticated(): boolean {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }
  isLogin(): boolean {
    if (!localStorage.getItem('token')) {
      return false;
    }

    return true;
  }

  isAdmin(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.ADMIN
    ) {
      return true;
    }

    return false;
  }
  isUser(): boolean {
    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.USER
    ) {
      return true;
    }

    return false;
  }
  isManager(): boolean {
    console.log('user===inapi--', this.user.user_type)

    console.log('this.isAuthenticated()', this.isAuthenticated())
    console.log('this.user.user_type', this.user.user_type)

    if (
      this.isAuthenticated() &&
      this.user.user_type === ConstantsService.MANAGER
    ) {
      return true;
    }

    return false;
  }

  jsonToFormData(
    jsonObject: any,
    parentKey?: any,
    carryFormData?: FormData
  ): FormData {
    const formData = carryFormData || new FormData();
    let index = 0;

    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
          let propName = parentKey || key;
          if (parentKey && this.isObject(jsonObject)) {
            propName = parentKey + '[' + key + ']';
          }
          if (parentKey && this.isArray(jsonObject)) {
            propName = parentKey + '[' + index + ']';
          }
          if (jsonObject[key] instanceof File) {
            formData.append(propName, jsonObject[key]);
          } else if (jsonObject[key] instanceof FileList) {
            for (let j = 0; j < jsonObject[key].length; j++) {
              formData.append(
                propName + '[' + j + ']',
                jsonObject[key].item(j)
              );
            }
          } else if (
            this.isArray(jsonObject[key]) ||
            this.isObject(jsonObject[key])
          ) {
            this.jsonToFormData(jsonObject[key], propName, formData);
          } else if (typeof jsonObject[key] === 'boolean') {
            formData.append(propName, +jsonObject[key] ? '1' : '0');
          } else {
            formData.append(propName, jsonObject[key]);
          }
        }
      }
      index++;
    }

    return formData;
  }

  isArray(val: any) {
    const toString = {}.toString;

    return toString.call(val) === '[object Array]';
  }

  isObject(val: any) {
    return !this.isArray(val) && typeof val === 'object' && !!val;
  }

  checkVerificationCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/verify-email`;
    return this.http.post<any>(url, data);
  }

  resendVerificationCode(data: any): Observable<any> {
    const url = `${apis.baseUrl}/public/resend-code`;
    return this.http.post<any>(url, data);
  }

  checkVerifyCode(data: any): Observable<any> {
    const url = `${this.baseUrl}/verify-code`;
    return this.http.post<any>(url, data);
  }
  resetPass(data: any): Observable<any> {
    const url = `${this.baseUrl}/public/reset-password`;
    return this.http.post<any>(url, data);
  }

  translate(key: string) {
    return this.ts.get(key);
  }

  getCurrentLang() {
    let lang = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : this.ts.getBrowserLang();
    if (!lang) {
      lang = 'en';
    }
    return lang;
  }

  changeLanguage(lang: string) {
    this.ts.currentLang = lang;
    localStorage.setItem('lang', lang);
    this.ts.setDefaultLang(lang);
    this.ts.use(lang);
    location.reload();
  }

  userImageUrl(userId?: number) {
    userId = userId ? userId : -1;
    return `${apis.baseUrl}/public/profile-image/${userId}`;
  }

}
