import { PipeTransform, Pipe } from '@angular/core'
import * as moment from 'moment'
import { ConstantsService } from '../services/constants.service'

@Pipe({ name: 'df' })
export class FormatDatePipe implements PipeTransform {
    constructor (
        private cs:ConstantsService
    ) {
    }

    transform (date: string | Date, format?: string): any {
        if(!format){
            format = this.cs.DATE_TIME_FORMAT.SYN_DATE;
        }
        return moment(date).format(format)
    }
}