import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'time'
})
export class FormatTimePipe implements PipeTransform {

    transform(value: string): string {
        if (!value) return '';

        // Parse the input string as a Date object
        const date = new Date(value);

        // Extract hours, minutes, and seconds in UTC
        let hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();

        // Determine AM/PM suffix
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // Format the time components to ensure two digits
        const formattedHours = hours.toString();
        const formattedMinutes = this.padZero(minutes);
        const formattedSeconds = this.padZero(seconds);

        // Return the formatted time string
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    // Helper method to pad single digit numbers with a leading zero
    private padZero(value: number): string {
        return value < 10 ? '0' + value : value.toString();
    }

}
